import Vue from 'vue'
import VueRouter from 'vue-router'
import request from "../plugins/axios.js";

Vue.use(VueRouter)

const routes = [{ //0.超级管理员
    path: '/admin',
    component: () => import('../views/layout.vue'),
    children: [
        /* {
             path: "user",
             component: () => import('../views/admin/user.vue')
         },*/
    ]
},
    {
        path: "/",
        component: () => import('../views/admin/home.vue')
    },
    {
        path: "/home",
        component: () => import('../views/admin/home.vue')
    },
    {
        path: "/exam",
        name: "密码知识题库",
        component: () => import('../views/admin/exam.vue')
    },
    {
        path: "/answert",
        component: () => import('../views/admin/answert.vue')
    },
    {
        path: "/answer",
        component: () => import('../views/admin/answer.vue')
    },
    {
        path: "/reg",
        component: () => import('../views/reg.vue')
    },
    {
        path: "/login",
        component: () => import('../views/login.vue')
    },

]

const router = new VueRouter({
    mode: 'history', //去掉url中的#
    base: process.env.BASE_URL,
    routes
})

//全局前置路由守卫---初始化的时候被调用、每次路由切换的时候被调用
router.beforeEach((to, from, next) => {
    let token = window.localStorage.getItem("token");
    let role = window.localStorage.getItem("role");
    let company = window.localStorage.getItem("company");

    //即判断用户是否进入了需要鉴权的路由下
    console.log(to.path)
    if (to.path == '/') {
        next()
    } else {
        //如果不是，则直接放心即可


        // request({
        //   url: "/admin/Read/Auth",
        //   data: {
        //     role,
        //     url: to.path,
        //   },
        // }).then((ret) => {
        //   if (ret.code == 1) {
        next()
        //   }
        // });
    }
})

export default router
